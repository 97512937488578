/* eslint-disable no-useless-escape */

import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { TransitionState } from 'gatsby-plugin-transition-link'
import posed from 'react-pose'
import { Box, Flex, Heading, Image } from 'rebass'
import styled from 'styled-components'
import VideoBackground from '../components/video-background'
/*import { window } from 'browser-monads'*/
import { isMobile } from "react-device-detect"
import { withPreview } from 'gatsby-source-prismic'

export const FadingContent = posed.div({
  exiting: { opacity: 0 },
})

const ContactWrap = styled.div`
  padding: 18.4rem 2.6rem 14.4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 34vh 4.8rem;
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    padding-top: 52vh;
  }
`

const ContactBackground = styled.div`
  position: fixed;
  background-color: #3d00ff;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Intro = styled(Heading)`
  position: relative;
  color: #fff;
  font-family: ${props => props.theme.fonts.variable};
  font-weight: 380;
  font-size: ${props => props.theme.fontSizes[6]}px;
  line-height: 0.92;
  text-align: left;
  max-width: 102.4rem;
  margin-bottom: 2.4rem;

  .ContactWrap--dark & {
    color: ${props => props.theme.colors.black};
  }

  .ContactWrap--difference & {
    mix-blend-mode: difference;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    font-size: ${props => props.theme.fontSizes[7]}px;
    margin-bottom: 3.2rem;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    font-size: ${props => props.theme.fontSizes[9]}px;
    margin-bottom: 7rem;
  }
`

export const Contacts = styled(Flex)`
  flex-direction: column;
  z-index: 1;
  
  > div {
    & + * {
      margin-top: ${props => props.theme.space[4]}px;
    }
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: ${props => props.theme.space[10]}px;
    
    > div {
      flex: 0 0 33.3334%;
      
      & + * {
        margin-top: 0;
      }
    }
  }
`

export const ContactCard = styled(Box)`
  color: ${props => props.theme.colors.black};
  font-family: ${props => props.theme.fonts.variable};

  .ContactWrap--light & {
    h2, a, span {
      color: #fff;
    }
  }

  .ContactWrap--difference & {
    mix-blend-mode: difference;
  }
  
  h2 {
    font-size: ${props => props.theme.fontSizes[3]}px;
    font-weight: 500;
    margin-bottom: 0.4rem;
  }
  
  a, span {
    display: inline-block;
    font-size: ${props => props.theme.fontSizes[4]}px;
    font-weight: 580;
  }
  
  a {
    color: ${props => props.theme.colors.blue};
    text-decoration: none;
  }
  
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    padding: 0 ${props => props.theme.space[6]}px;
    
    &:first-child {
      padding-left: 0;
    }
    
    h2 {
      font-size: ${props => props.theme.fontSizes[4]}px;
    }
    
    a, span {
      font-size: ${props => props.theme.fontSizes[5]}px;   
    }
  }
`

const ContactInner = ({ transitionStatus, contact }) => {

  const overlayStyle = contact.content_overlay_style.toLowerCase()

  const background = ((contact.background_video || contact.background_vimeo_video) && !isMobile) ?
    <VideoBackground
      poster={contact.background_image && contact.background_image.url}
      source={contact.background_video.url}
      vimeo={contact.background_vimeo_video}
      fixed={true}
    />
    :
    contact.background_image ?
      <Image
        src={contact.background_image.url}
        alt={contact.background_image.alt}
      />
      : null

  const time = new Date().getHours()
  let timeText

  if (time < 12) {
    timeText = 'Good morning!'
  } else if (time < 18) {
    timeText = 'Good afternoon!'
  } else {
    timeText = 'Good evening!'
  }

  return (
    <Layout
      transitionStatus={transitionStatus}
      headerVariant={overlayStyle}
    >
      <SEO
        title={contact.social_title || 'Contact Us'}
        description={contact.social_description}
        image={contact.social_image}
      />

      <FadingContent pose={transitionStatus}>
        <ContactWrap className={overlayStyle ? `ContactWrap--${overlayStyle}` : ''}>
          <ContactBackground>
            {background}
          </ContactBackground>

          <Intro mb={[4, 5, 8]}>{timeText}</Intro>

          <Contacts>
            <ContactCard>
              <h2>New business</h2>
              <a href="mailto:hello@ronikdesign.com"
                 data-icon="small"
                 data-cursor>
                hello@ronikdesign.com
              </a>
            </ContactCard>

            <ContactCard>
              <h2>Join the team</h2>
              <a href="mailto:work@ronikdesign.com"
                 data-icon="small"
                 data-cursor>
                work@ronikdesign.com
              </a>
            </ContactCard>
          </Contacts>
        </ContactWrap>
      </FadingContent>
    </Layout>
  )
}

const Contact = ({ data }) => {
  const contact = data.allPrismicContact.edges[0].node.data

  return (
    <TransitionState>
      {({ transitionStatus }) => (
        <ContactInner
          transitionStatus={transitionStatus}
          contact={contact}
        />

      )}
    </TransitionState>
  )
}

export default withPreview(Contact)

export const query = graphql`
  {
    allPrismicContact {
      edges {
        node {
          data {
            background_image {
              url
            }
            background_video {
              url
            }
            background_vimeo_video
            content_overlay_style
          }
        }
      }
    }
  }
`
